import authAxios from '../axios/authAxios';
class CommonAPI {
	
	static getCustomerPortfolio(accessToken) {
		return authAxios.get('/v1/common/customer_portfolio/', { headers: { Authorization: `Bearer ${accessToken}` } });
	}

}

export default CommonAPI;
