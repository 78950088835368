import React, { useState, useEffect } from 'react';
import Card from 'react-bootstrap/Card';
import BootstrapTable from 'react-bootstrap-table-next';
import Col from 'react-bootstrap/esm/Col';
import Row from 'react-bootstrap/esm/Row';
import ToolkitProvider, { Search, CSVExport } from 'react-bootstrap-table2-toolkit';

const { ExportCSVButton } = CSVExport;
const { SearchBar, ClearSearchButton } = Search;

export default function EventsSortingTable(props) {
	const {
		selectedEquipment,
		setEventFile,
		tableData,
	} = props;
	
	const [csvFileName, setCsvFileName] = useState('spreadsheet.csv');

	useEffect(() => {
		if (selectedEquipment) {
			setCsvFileName(selectedEquipment.label + ' Events Sorting Table.csv');
		} else {
			setCsvFileName('spreadsheet.csv');
		}
	}, [selectedEquipment]);

	const selectRow = {
		mode: 'radio',
		clickToSelect: true,
		hideSelectColumn: true,
		bgColor: '#9bcaff',
	};

	const onRowSelect = {
		onClick: (e, row, rowIndex) => {
			setEventFile({ label: row['Timestamps'], value: row.s3_bucket_key_pair });
		},
	};

	return (
		<div style={{width:'fit-content'}}>
			{tableData.columns.length <= 1 ? null : (
				<Card>
					<Card.Header>
						Events Sorting Table- {selectedEquipment ? selectedEquipment.label : null}
					</Card.Header>
					<Row className='eventSortTable_row'>
						<Col className='col-12 eventSorting_tableCSS'>
							<ToolkitProvider
								keyField='id'
								data={tableData.rows}
								columns={tableData.columns}
								exportCSV={{
									fileName: csvFileName,
									onlyExportFiltered: true,
									exportAll: false,
								}}
								search
								bootstrap4>
								{(props) => (
									<div>
										<div className='exportCSV_ctn'>
											<ExportCSVButton
												className='event_exportBtn btn-primary'
												{...props.csvProps}>
												Export CSV
											</ExportCSVButton>
										</div>

										<div className='eventSearchBox_ctn'>
											<SearchBar className='eventSearchBox' {...props.searchProps} />

											<ClearSearchButton
												className='eventSearch_clearBtn btn-primary'
												{...props.searchProps}
											/>
										</div>

										<BootstrapTable
											{...props.baseProps}
											rowStyle={{ fontSize: 14, width: 10, whiteSpace: 'nowrap' }}
											noDataIndication='No Events Found'
											selectRow={selectRow}
											rowEvents={onRowSelect}
										/>
									</div>
								)}
							</ToolkitProvider>
						</Col>
					</Row>
				</Card>
			)}
		</div>
	);
}
